import { useCallback, useState } from "react";
import { formatCurrency } from "@tiicker/util/lib/formats";
import { useFetchUser, useMutationBundle, useQueryBundle } from "source/hooks";
import { DisconnectInstitutionLoginForUser } from "$gql/mutations/general/DisconnectInstitutionLoginForUser.gen";
import { UserLoginFragment } from "$gql/fragments/general/UserLogin.gen";
import { UpdateHoldingsForLogin } from "$gql/mutations/general/UpdateHoldingsForLogin.gen";
import { PlaidLoginStatus } from "$gql/types.gen";
import ConfirmationModal from "components/core/ConfirmationModal/ConfirmationModal";
import BrokerageAccount from "./BrokerageAccount";
import classnames from "classnames";
import LinkIcon from "/public/icons/link.svg";
import ChevronDownIcon from "/public/icons/chevron-down.svg";
import ChevronUpIcon from "/public/icons/chevron-up.svg";
import RefreshIcon from "/public/icons/refresh.svg";
import BrokenLinkIcon from "/public/icons/broken_link.svg";
import styles from "./BrokerageLogin.module.scss";
import { useInterval } from "components/PlaidLink/PollPlaidStatus";
import { GetPlaidSyncStatus } from "$gql/queries/general/GetPlaidSyncStatus.gen";
import { useUser } from "components/UserContext/UserContext";
import RedirectToFixOAuth from "components/Dashboard/AccountIssue/RedirectToFixOAuth";

interface Props {
  login: UserLoginFragment;
  loginIndex: number;
  isExpanded: boolean;
  onSelect: (index: number | undefined) => void;
}

const BrokerageLogin = (props: Props) => {
  const userContext = useUser();
  const { login, loginIndex, isExpanded, onSelect } = props;

  const { user: currentUser } = useFetchUser();
  const [showDisconnectConfirmation, setShowDisconnectConfirmation] =
    useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState(login.isLoadingPlaidRecords);

  const getLoginStatus = useQueryBundle(GetPlaidSyncStatus, {
    skip: true,
  });

  useInterval(
    () => {
      if (isRefreshing) {
        getLoginStatus
          .refetch({
            institutionLoginId: props.login.id,
          })
          .then((result) => {
            if (
              result.data.getInstitutionLogin &&
              (!result.data.getInstitutionLogin.isLoadingPlaidRecords ||
                result.data.getInstitutionLogin.plaidStatus ===
                  PlaidLoginStatus.Verifying)
            ) {
              setIsRefreshing(false);
            }
          });
      }
    },
    isRefreshing ? 2000 : null
  );

  const [refreshHoldings] = useMutationBundle(UpdateHoldingsForLogin);
  const onRefreshAccount = useCallback(() => {
    setIsRefreshing(true);
    refreshHoldings({
      variables: {
        institutionLoginId: login.id,
        forceUpdate: true,
      },
    });
  }, [currentUser?.id]);

  const [disconnectAccount] = useMutationBundle(
    DisconnectInstitutionLoginForUser
  );
  const onDisconnectAccount = useCallback(
    (institutionLoginId: number) => {
      disconnectAccount({
        variables: { id: institutionLoginId },
      }).then((r) => {
        if (
          r.data &&
          r.data.removeInstitutionLoginForUser.institutionLogins
            .filter((x) => !x.inactive)
            .map((l) => l.id)
            .includes(institutionLoginId)
        ) {
          // TODO: Handle error on disconnect
        } else {
          onSelect(undefined);
        }
      });
    },
    [disconnectAccount]
  );

  const totalValue = login.institutionAccounts.reduce(
    (total, account) =>
      total +
      (account.cashValue || 0) +
      (account.stockValue || 0) +
      (account.otherInvestmentsValue || 0),
    0
  );

  const badState =
    login.plaidStatus === PlaidLoginStatus.NeedsAuthentication ||
    login.plaidStatus === PlaidLoginStatus.NeedsReconnect;

  const [launchDirectReconnect, setLaunchDirectReconnect] =
    useState<boolean>(false);
  const click = () => {
    if (login.plaidInstitutionId) {
      userContext.openPlaid(login.id);
    } else {
      setLaunchDirectReconnect(true);
    }
  };

  return (
    <>
      <div
        className={styles.BrokerageLogin__row}
        onClick={() =>
          isExpanded ? onSelect(undefined) : onSelect(loginIndex)
        }
      >
        <div className={styles.BrokerageLogin__row__broker}>
          {login.institutionName}
          {badState ? (
            <span className={styles.BrokerageLogin__row__broker__disconnected}>
              <BrokenLinkIcon /> Connection issues.{" "}
              <a onClick={click}>Lets fix it.</a>
              {launchDirectReconnect && (
                <RedirectToFixOAuth loginId={login.id} />
              )}
            </span>
          ) : (
            <div className={styles.BrokerageLogin__row__broker__accounts}>
              {`${login.institutionAccounts.length} ${
                login.institutionAccounts.length === 1 ? "Account" : "Accounts"
              }`}
            </div>
          )}
        </div>
        <div className={styles.BrokerageLogin__row__accounts}>
          {login.institutionAccounts.length}
        </div>
        <div className={styles.BrokerageLogin__row__value}>
          {formatCurrency(totalValue)}
        </div>
        <div className={styles.BrokerageLogin__row__actions}>
          {isExpanded ? (
            <ChevronUpIcon
              className={styles.BrokerageLogin__row__action}
              // onClick={() => onSelect(undefined)}
            />
          ) : (
            <ChevronDownIcon
              className={styles.BrokerageLogin__row__action}
              // onClick={() => onSelect(loginIndex)}
            />
          )}
        </div>
      </div>
      <div
        className={classnames(
          styles.BrokerageLogin__rowDetails,
          !isExpanded ? styles.BrokerageLogin__rowDetails__closed : undefined
        )}
      >
        <div className={styles.BrokerageLogin__rowDetails__innerContainer}>
          {login.institutionAccounts.map((account) => (
            <div className={styles.BrokerageLogin__rowDetails__account}>
              <BrokerageAccount
                key={`brokerage-account-${account.id}`}
                accountName={account.name || ""}
                accountNumber={account.mask || ""}
                cashValue={account.cashValue || 0}
                stockValue={account.stockValue || 0}
                otherInvestmentsValue={account.otherInvestmentsValue || 0}
              />
            </div>
          ))}
          <div className={styles.BrokerageLogin__rowDetails__actions}>
            {isRefreshing ? (
              <div className={styles.BrokerageLogin__rowDetails__action}>
                <RefreshIcon
                  className={
                    styles.BrokerageLogin__rowDetails__actionIcon__spinning
                  }
                />
                <span>Refreshing...</span>
              </div>
            ) : (
              <>
                {!badState && (
                  <div
                    className={styles.BrokerageLogin__rowDetails__action}
                    onClick={onRefreshAccount}
                  >
                    <RefreshIcon
                      className={styles.BrokerageLogin__rowDetails__actionIcon}
                    />
                    <span>Refresh</span>
                  </div>
                )}
                <div
                  className={styles.BrokerageLogin__rowDetails__action}
                  onClick={() => setShowDisconnectConfirmation(true)}
                >
                  <LinkIcon
                    className={styles.BrokerageLogin__rowDetails__actionIcon}
                  />
                  <span>Disconnect</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={`Disconnect ${login.institutionName}`}
        description={`Are you sure you would like to disconnect ${login.institutionName}?`}
        visible={showDisconnectConfirmation}
        closeText="Cancel"
        confirmText="Remove it"
        onClose={() => setShowDisconnectConfirmation(false)}
        onConfirm={() => {
          setShowDisconnectConfirmation(false);
          onDisconnectAccount(login.id);
        }}
      />
    </>
  );
};

export default BrokerageLogin;
